@charset "UTF-8";
:root {
  --dark-blue: #00314d;
  --blue: #009fe3;
  --beige: #f8e8dd;
  --yellow: #f5ab3d;
  --white: #fff;
  --red: #e45c54;
  --font-family: "Gotham Pro", sans-serif;
  --second-family: "Geometria", sans-serif;
}

html {
  scroll-behavior: smooth;
  scroll-padding: 100px;
}

.main {
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: var(--font-family);
  transition: 300ms;
}

body.fixed {
  overflow: hidden;
  height: 100vh;
}

a {
  color: #000;
}

button {
  border: none;
  cursor: pointer;
}

.h2 {
  font-weight: 700;
  font-size: clamp(23px, 3.5vw, 48px);
  line-height: 120%;
  color: var(--dark-blue);
}

.container {
  width: 1600px;
  margin: 0 auto;
  max-width: 1800px;
}

.t16 {
  font-size: clamp(14px, 2vw, 16px);
}

.t18 {
  font-size: clamp(16px, 2.5vw, 18px);
}

.t20 {
  font-size: clamp(18px, 3vw, 20px);
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header:has(.header_navigation.active) .btn1 .icon-right:before,
.header:has(.header_navigation.active) .btn1 .icon-right::after,
.header:has(.header_navigation.active) .btn1 .icon-left:before,
.header:has(.header_navigation.active) .btn1 .icon-left::after {
  background-color: var(--dark-blue);
}

.menu {
  display: none;
}

.btn1 {
  position: relative;
  width: 60px;
  height: 60px;
  transition-duration: 0.5s;
  z-index: 99999;
}
.btn1 .icon-left {
  transition-duration: 0.5s;
  position: absolute;
  height: 3px;
  width: 15px;
  top: 30px;
  background-color: var(--dark-blue);
  left: 0px;
}
.btn1 .icon-left:before {
  transition-duration: 0.5s;
  position: absolute;
  width: 15px;
  height: 3px;
  background-color: var(--dark-blue);
  content: "";
  top: -10px;
}
.btn1 .icon-left:after {
  transition-duration: 0.5s;
  position: absolute;
  width: 15px;
  height: 3px;
  background-color: var(--dark-blue);
  content: "";
  top: 10px;
}
.btn1 .icon-left:hover {
  cursor: pointer;
}
.btn1 .icon-right {
  transition-duration: 0.5s;
  position: absolute;
  height: 3px;
  width: 15px;
  top: 30px;
  background-color: var(--dark-blue);
  left: 15px;
}
.btn1 .icon-right:before {
  transition-duration: 0.5s;
  position: absolute;
  width: 15px;
  height: 3px;
  background-color: var(--dark-blue);
  content: "";
  top: -10px;
}
.btn1 .icon-right:after {
  transition-duration: 0.5s;
  position: absolute;
  width: 15px;
  height: 3px;
  background-color: var(--dark-blue);
  content: "";
  top: 10px;
}
.btn1.open .icon-left {
  transition-duration: 0.5s;
  background: transparent;
}
.btn1.open .icon-left:before {
  transform: rotateZ(45deg) scaleX(1.4) translate(0px, -1px);
}
.btn1.open .icon-left:after {
  transform: rotateZ(-45deg) scaleX(1.4) translate(4px, -5px);
}
.btn1.open .icon-right {
  transition-duration: 0.5s;
  background: transparent;
}
.btn1.open .icon-right:before {
  transform: rotateZ(-45deg) scaleX(1.4) translate(-1px, -1px);
}
.btn1.open .icon-right:after {
  transform: rotateZ(45deg) scaleX(1.4) translate(-5px, -5px);
}
.btn1:hover {
  cursor: pointer;
}

.header {
  padding: 13px 0;
  height: 90px;
  position: fixed;
  width: 100vw;
  z-index: 100;
  top: 0;
  left: 0;
}
.header_logoBox {
  height: 100%;
}
.header_logoBox img {
  height: 100%;
  width: auto;
}
.header .wite {
  position: relative;
  bottom: -20px;
}
.header .dark {
  display: none;
}
.header.active.burg {
  top: -90px;
}
.header.active .dark, .header.burg .dark {
  display: block;
}
.header.active .wite, .header.burg .wite {
  display: none;
}
.header.active {
  top: 0px;
  background: var(--white);
}
.header.burg {
  background: var(--white);
}
.header .container {
  justify-content: space-between;
}
.header_navigate {
  justify-content: space-between;
  gap: 75px;
}
.header_link {
  font-weight: 700;
  text-wrap: nowrap;
  color: var(--dark-blue);
}
.header_link:hover {
  color: var(--yellow);
  text-decoration: underline;
}

.hello {
  z-index: 1;
  height: 49vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  position: relative;
  display: flex;
  align-items: flex-end;
}
.hello.load * {
  display: none;
}
.hello.mob {
  display: none;
}
.hello_fon {
  position: absolute;
  left: 0;
  top: 0;
}
.hello_fon.bg_1, .hello_fon.bg_3 {
  width: 100%;
  height: 100%;
}
.hello_fon.bg_1 {
  z-index: 4;
}
.hello_fon.bg_2 {
  z-index: 3;
  height: 45vw;
  animation: 3s fonAnim ease;
}
.hello_fon.bg_3 {
  z-index: 2;
}
.hello_fon.bg_4 {
  top: auto;
  bottom: -66vw;
  z-index: 5;
  overflow: hidden;
  width: 100%;
  animation: 5s strelAnim ease;
  height: 100vw;
}
.hello_fon.bg_4 img {
  width: 107vw;
  height: 124vw;
  min-width: 99vw;
  min-height: 49vw;
  position: absolute;
  bottom: -4vw;
  left: -4vw;
  pointer-events: none;
}
@keyframes fonAnim {
  0% {
    top: 100%;
    opacity: 0;
  }
  30% {
    top: 100%;
    opacity: 0;
  }
  100% {
    top: 0%;
    opacity: 1;
  }
}
@keyframes strelAnim {
  0% {
    height: 0vw;
  }
  100% {
    height: 188vw;
  }
}
.hello_content {
  width: 100%;
  z-index: 5;
  position: absolute;
  bottom: 33%;
  flex-direction: column;
  gap: 50px;
  animation: 3s contentAnim ease;
}
@media screen and (max-width: 1250px) {
  .hello_content {
    bottom: 25%;
  }
}
.hello_content_name {
  font-weight: 500;
  font-size: clamp(18px, 2.5vw, 32px);
  color: var(--white);
  gap: 7px;
  align-self: baseline;
  padding-left: 8%;
}
.hello_content_name img {
  width: 32px;
  height: 32px;
}
.hello_content_but {
  font-weight: 500;
  color: var(--dark-blue);
  padding: 16px 32px;
  border-radius: 60px;
  background: var(--beige);
}
.hello_content_but:hover {
  background: var(--yellow);
  color: var(--white);
}
@keyframes contentAnim {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.hello_contact {
  gap: 10px;
  position: absolute;
  z-index: 5;
  bottom: 20px;
  right: 10%;
  width: -moz-min-content;
  width: min-content;
  overflow: hidden;
}
.hello_contact_item {
  opacity: 0.5;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background: var(--white);
  position: relative;
}
.hello_contact_item:hover {
  opacity: 1;
}
.hello_contact_item:first-child {
  animation: 2s contactAnim ease-in;
}
.hello_contact_item:last-child {
  animation: 3.5s contactAnim ease-in;
}
@keyframes contactAnim {
  0% {
    bottom: -36px;
  }
  70% {
    bottom: -36px;
  }
  100% {
    bottom: 0px;
  }
}

.preloader #splash {
  background: rgba(255, 255, 255, 0.5019607843);
  background-repeat: repeat-y;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
}
.preloader #splash.active {
  animation: splash 3s ease-in;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}
.preloader #loader {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0);
}
.preloader #loader:after {
  content: "";
  position: absolute;
  left: 50%;
  margin-left: -8px;
  bottom: -170px;
  width: 3px;
  background: #fff;
  background: linear-gradient(to bottom, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 50%, rgba(255, 255, 255, 0) 100%);
  height: 200px;
}
.preloader #loader:before {
  content: "";
  position: absolute;
  left: 50%;
  margin-left: 8px;
  bottom: -190px;
  width: 3px;
  background: #000;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0) 100%);
  height: 200px;
}
.preloader #splash .anim {
  height: 100%;
  position: absolute;
  left: 50%;
  width: 100px;
  transform: translate(-50%, 100%);
}
.preloader #splash .anim.active {
  animation: loader 4s linear;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}
@keyframes loader {
  0% {
    transform: translate(-50%, 110%);
  }
  30% {
    transform: translate(-50%, 50%);
  }
  100% {
    transform: translate(-50%, 0%);
  }
}
@keyframes splash {
  0% {
    transform: translate(0%, 0%);
    background: rgba(255, 255, 255, 0.5019607843);
  }
  50% {
    transform: translate(0%, 0%);
  }
  80% {
    background: rgba(255, 255, 255, 0);
  }
  100% {
    background: transparent;
    transform: translate(0%, -100%);
  }
}

.oKrasnoyarsk {
  margin: 150px 0;
  position: relative;
  z-index: 10;
}
.oKrasnoyarsk .container {
  flex-direction: column;
  align-items: stretch;
  gap: 36px;
}
.oKrasnoyarsk_menu {
  justify-content: space-between;
  width: 100%;
}
.oKrasnoyarsk_menu_text {
  color: var(--dark-blue);
  font-weight: 400;
  max-width: 636px;
}
.oKrasnoyarsk_menu_switch {
  gap: 6px;
}
.oKrasnoyarsk_menu_switch_item {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 1px solid var(--yellow);
  cursor: pointer;
  position: relative;
  background: var(--yellow);
  position: relative;
}
.oKrasnoyarsk_menu_switch_item svg {
  fill: #fff;
}
.oKrasnoyarsk_menu_switch_item:hover {
  transform: scale(1.1);
}
.oKrasnoyarsk_menu_switch_item:active {
  transform: scale(1);
}
.oKrasnoyarsk_menu_switch_item.inactive {
  background: #fff;
  pointer-events: none;
}
.oKrasnoyarsk_menu_switch_item.inactive svg {
  fill: #f5ab3d;
}
.oKrasnoyarsk_slider {
  width: 100%;
  max-width: 100%;
  height: 370px;
  gap: 20px;
  margin-top: 60px;
}
.oKrasnoyarsk_slider_item {
  padding: 23px;
  width: 95%;
  max-width: 300px;
  height: 370px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  align-items: flex-end;
  border-radius: 19px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}
.oKrasnoyarsk_slider_item::after, .oKrasnoyarsk_slider_item::before {
  transition: 300ms;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0;
  background: rgba(245, 171, 61, 0.4117647059);
}
.oKrasnoyarsk_slider_item::before {
  left: 100%;
}
.oKrasnoyarsk_slider_item_name {
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  text-align: center;
  color: var(--white);
  z-index: 10;
  position: relative;
  bottom: 0;
  height: -moz-fit-content;
  height: fit-content;
}
.oKrasnoyarsk_slider_item:hover::after {
  opacity: 1;
}
.oKrasnoyarsk_slider_item:hover .oKrasnoyarsk_slider_item_name {
  bottom: 40%;
  text-shadow: 1px 1px 3px black;
}

.test {
  background: url(/public/img/test/fon.webp);
  margin: 140px 0px 0px 0px;
  z-index: 1;
  height: 45vw;
  background-position: center bottom !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  position: relative;
}
.test ._butN {
  display: none;
  position: absolute;
  width: 15px;
  border: 2px solid #000;
  height: 15px;
  right: 52px;
  top: 30px;
  border-bottom: none;
  border-left: none;
  transform: rotate(45deg);
  cursor: pointer;
  animation: st1 2s infinite;
}
@keyframes st1 {
  0% {
    right: 52px;
  }
  50% {
    right: 32px;
  }
  100% {
    right: 52px;
  }
}
.test .container {
  position: relative;
}
.test_progressBar {
  position: absolute;
  height: 50px;
  width: 78%;
  bottom: 0%;
  z-index: 999;
  right: 0;
  justify-content: space-between;
}
.test_progressBar.noact {
  display: none;
}
.test_progressBar_check {
  font-weight: 500;
  font-size: 45px;
  width: -moz-min-content;
  width: min-content;
  text-shadow: 0px 0px 3px #fff;
  color: var(--dark-blue);
}
.test_progressBar_step {
  position: relative;
  z-index: 1;
  width: 90%;
  margin-right: 20px;
}
.test_progressBar .steps {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 1100px;
}
.test_progressBar .step {
  width: 20px;
  height: 20px;
  background: #fff;
  border: 2px solid #acaca6;
  border-radius: 50%;
  transition: background 1s;
  pointer-events: none;
}
.test_progressBar .step.selected {
  border: 2px solid var(--yellow);
}
.test_progressBar .step.completed {
  border: 2px solid var(--yellow);
  background: var(--yellow);
}
.test_progressBar .progress {
  position: absolute;
  width: 1100px;
  height: 50%;
  border-bottom: 2px solid #acaca6;
  z-index: -1;
}
.test_progressBar .percent {
  position: absolute;
  width: 0;
  max-width: 100%;
  height: 100%;
  border-bottom: 3px solid var(--yellow);
  z-index: 1;
  transition: width 1s;
  bottom: -3px;
}
.test .h2 {
  color: #fff;
  font-size: clamp(16px, 1.8vw, 48px);
  line-height: 120%;
}
.test .container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  align-items: end;
}
.test_content {
  background: var(--dark-blue);
  border-radius: 20px;
  width: 800px;
  height: 32vw;
  min-height: 58%;
  padding: 50px 60px;
  position: relative;
  left: 29%;
  bottom: -2%;
  background-image: url(/public/img/test/fon2.png);
  background-position: bottom right;
  background-repeat: no-repeat;
  max-width: 100%;
  overflow: hidden;
  z-index: 5;
}
.test_content.ot {
  z-index: 4;
}
.test_content .alice-carousel__stage-item {
  width: 101% !important;
  min-width: 101% !important;
  max-width: 101% !important;
  height: 26vw;
}
.test_content_item {
  flex-direction: column;
  align-items: flex-start;
  gap: 35px;
  height: 100%;
}
.test_content_item_butBox {
  gap: 10px;
}
.test_content_item_text {
  font-weight: 400;
  line-height: 120%;
  color: var(--white);
  font-size: clamp(12px, 1vw, 20px);
}
.test_content_item_text li {
  padding-bottom: 20px;
  display: block;
  max-width: 70%;
}
.test_content_item_butt {
  border-radius: 60px;
  padding: 16px 35px;
  width: -moz-min-content;
  width: min-content;
  height: 47px;
  background: var(--yellow);
  color: var(--white);
  border: 1px solid var(--yellow);
  justify-content: space-between;
  cursor: pointer;
  text-wrap: nowrap;
  display: flex;
  gap: 30px;
  opacity: 1;
  margin-top: auto;
  margin-bottom: 25px;
}
.test_content_item_butt.onActive {
  pointer-events: none;
  opacity: 0.5;
}
.test_content_item_butt svg {
  fill: var(--white);
}
.test_content_item_butt:hover {
  color: var(--yellow);
  background: var(--white);
}
.test_content_item_butt:hover svg {
  fill: var(--yellow);
}
.test_content_item_boxOpr {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.test_content_item_boxOpr label {
  cursor: pointer;
  display: flex;
  gap: 20px;
}
.test_content_item_boxOpr label:hover {
  text-decoration: underline;
  text-decoration-color: var(--white);
}
.test_content_item_boxOpr label div {
  content: "";
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  border-radius: 3px;
  border: 2px solid #fff;
  display: block;
  background: var(--white);
}
.test_content_item_boxOpr label input {
  display: none;
}
.test_content_item_boxOpr label:has(input:checked) div {
  background: var(--blue);
}
.test_img {
  width: 45vw;
  height: 45vw;
  position: relative;
  overflow: hidden;
  z-index: 4;
  bottom: -2%;
}
.test_img .alice-carousel__stage-item {
  opacity: 0;
}
.test_img .alice-carousel__stage-item.__target {
  opacity: 1;
}
.test_img_item {
  position: relative;
  width: 45vw;
  height: 45vw;
  background-size: contain !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  border-radius: 25px;
}
.test_img_item.otvets {
  padding: 7.5%;
}
.test_img_item_title {
  font-weight: 700;
  font-size: clamp(24px, 3vw, 32px);
  line-height: 130%;
  padding-bottom: 40px;
  -webkit-text-stroke: 1px var(--dark-blue);
  color: white;
  text-shadow: 2px 2px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}
.test_img_item_content {
  padding-left: 25px;
  overflow-y: auto;
  height: 90%;
  scrollbar-width: thin;
  scrollbar-color: #f5b047 white;
}
.test_img_item_content::-webkit-scrollbar, .test_img_item_content::-webkit-scrollbar {
  height: 12px;
  width: 12px;
}
.test_img_item_content::-webkit-scrollbar-track, .test_img_item_content::-webkit-scrollbar-track {
  background: white;
}
.test_img_item_content::-webkit-scrollbar-thumb, .test_img_item_content::-webkit-scrollbar-thumb {
  background-color: #f5b047;
  border-radius: 5px;
  border: 3px solid white;
}
.test_img_item_content_item {
  padding-bottom: 35px;
  list-style-type: auto;
  font-weight: bold;
}
.test_img_item_content_item_link {
  color: var(--dark-blue);
  text-decoration: underline;
}
.test_img_item_content_item_mest {
  line-height: 120%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  text-decoration: underline;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
  color: var(--dark-blue);
  padding-bottom: 20px;
  display: block;
  cursor: pointer;
}
.test_img_item_content_item_opis {
  font-weight: 400;
  line-height: 120%;
  color: var(--dark-blue);
}

body.poapActive {
  overflow: hidden;
  padding-right: 16px;
}

.ekskurs {
  padding: 150px 0px;
  background: var(--beige);
  z-index: 1;
  background: url(/public/img/ekskurs/fon.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.ekskurs_hed {
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  padding: 36px 0px 60px 0px;
}
.ekskurs_hed_filter {
  align-items: start;
  gap: 15px;
}
.ekskurs_hed_filter label {
  display: block;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.03em;
  text-align: right;
  color: var(--white);
  cursor: pointer;
  text-wrap: nowrap;
  width: -moz-min-content;
  width: min-content;
}
.ekskurs_hed_filter label:hover {
  text-decoration: underline;
}
.ekskurs_hed_filter label input {
  display: none;
}
.ekskurs_hed_filter label:has(input:checked) {
  color: var(--yellow);
  text-decoration: underline;
}
.ekskurs_hed_filter .t16 {
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.03em;
  text-align: center;
  color: var(--dark-blue);
}
.ekskurs_hed .t18 {
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  text-align: center;
  color: var(--dark-blue);
}
.ekskurs_slider {
  width: 100%;
}
.ekskurs_slider_item {
  background: var(--white);
  border-radius: 20px;
  width: 90%;
  cursor: pointer;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: auto 100% !important;
  height: 300px;
  align-items: flex-end;
  overflow: hidden;
}
.ekskurs_slider_item .t18 {
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  text-align: center;
  color: var(--dark-blue);
  padding: 12px 40px;
  height: 25%;
  background: var(--white);
  width: 100%;
  transform: 500ms;
}
.ekskurs_slider_item .t18::after {
  content: attr(data-text);
}
.ekskurs_slider_item:hover .t18 {
  height: 100%;
  background: rgba(255, 255, 255, 0.5882352941);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}
.ekskurs_slider_item:hover .t18::after {
  content: attr(data-hover);
}

.restor {
  background-color: var(--dark-blue);
  background: url(/public/img/restor/fon.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 150px 0px;
}
.restor .h2 {
  color: var(--white);
  max-height: 630px;
  text-align: start;
}
.restor .container {
  flex-direction: column;
  gap: 60px;
}
.restor_menu {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 150px;
}
.restor_menu-left_switch {
  gap: 25px;
  justify-content: flex-start;
  align-items: start;
}
.restor_menu-left_switch_item {
  width: 100px;
  cursor: pointer;
  flex-direction: column;
  gap: 10px;
}
.restor_menu-left_switch_item input {
  display: none;
}
.restor_menu-left_switch_item_img {
  width: 92px;
  height: 75px;
  background: var(--white);
  padding: 10px;
  border-radius: 5px;
}
.restor_menu-left_switch_item_img img {
  height: auto;
  width: 100%;
  filter: brightness(0) saturate(100%) invert(10%) sepia(30%) saturate(6732%) hue-rotate(185deg) contrast(101%);
}
.restor_menu-left_switch_item_text {
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.03em;
  text-align: start;
  width: 100%;
}
.restor_menu-left_switch_item:has(input:checked) .restor_menu-left_switch_item_img {
  background: var(--yellow);
}
.restor_menu-left_switch_item:has(input:checked) .restor_menu-left_switch_item_img img {
  filter: brightness(0) invert(100%) sepia(100%) saturate(0%) hue-rotate(285deg) contrast(102%);
}
.restor_menu_chek {
  flex-direction: column;
  align-items: start;
  width: 100%;
  gap: 30px;
}
.restor_menu_chek .t18 {
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: var(--white);
}
.restor_menu_chek_list {
  align-items: start;
  gap: 15px;
}
.restor_menu_chek_list label {
  display: block;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.03em;
  text-align: right;
  color: var(--white);
  cursor: pointer;
  text-wrap: nowrap;
  width: -moz-min-content;
  width: min-content;
}
.restor_menu_chek_list label:hover {
  text-decoration: underline;
}
.restor_menu_chek_list label input {
  display: none;
}
.restor_menu_chek_list label:has(input:checked) {
  color: var(--yellow);
  text-decoration: underline;
}
.restor_menu_switchSlider .oKrasnoyarsk_menu_switch {
  justify-content: flex-end;
}
.restor_slide {
  width: 100%;
}
.restor_slide .alice-carousel__wrapper {
  overflow: visible;
}
.restor_slide .alice-carousel__stage-item {
  height: 536px;
  max-width: 500px;
}
.restor_slide_item {
  overflow: hidden;
  background: var(--white);
  border-radius: 20px;
  margin: auto;
  width: 97%;
}
.restor_slide_item_box {
  padding: 10px;
}
.restor_slide_item_img {
  background-size: cover !important;
  background-repeat: no-repeat;
  border-radius: 16px;
  position: relative;
  width: 100%;
  height: 327px;
}
.restor_slide_item_img_flags {
  width: 100%;
  justify-content: space-between;
  padding: 10px;
  align-items: flex-start;
}
.restor_slide_item_img_flags .t16 {
  font-weight: 400;
  color: #000;
  border-radius: 10px;
  padding: 7px 22px;
  background: var(--beige);
}
.restor_slide_item_img_flags_type {
  flex-direction: column;
  gap: 10px;
}
.restor_slide_item_img_flags_type_item {
  background: var(--yellow);
  padding: 5px;
  border-radius: 5px;
  width: 57px;
  height: 46px;
}
.restor_slide_item_img_flags_type_item img {
  width: 100%;
  height: auto;
  filter: brightness(0) invert(100%) sepia(100%) saturate(0%) hue-rotate(285deg) contrast(102%);
}
.restor_slide_item_name {
  font-weight: 700;
  text-transform: uppercase;
  color: var(--dark-blue);
  margin-bottom: 15px;
}
.restor_slide_item_info {
  flex-direction: column;
  align-items: start;
  padding: 15px;
  padding-left: 30px;
}
.restor_slide_item_info_item {
  flex-direction: column;
  align-items: start;
  gap: 8px;
}
.restor_slide_item_info_item_lab {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  min-height: 30px;
  justify-content: flex-start;
  cursor: pointer;
  color: var(--dark-blue);
}
.restor_slide_item_info_item_lab:hover {
  text-decoration: underline;
  text-decoration-color: var(--yellow);
}
.restor_slide_item_info_item_lab .kuh:not(:last-child)::after {
  content: ", ";
}
.restor_slide_item_info_item_lab img {
  padding-top: 4px;
}
.restor_slide_item_footer {
  background: rgba(248, 232, 221, 0.4901960784);
  width: 100%;
  justify-content: start;
  padding: 14px 35px;
  height: 56;
}

body {
  overflow-x: hidden;
}

.otel {
  margin: 120px 0px;
}
.otel_filter {
  padding: 36px 0px;
  justify-content: space-between;
}
.otel_filter_type {
  gap: 20px;
}
.otel_filter_type label {
  cursor: pointer;
  padding: 3px 5px;
  text-align: center;
  border-bottom: 2px solid var(--white);
}
.otel_filter_type label input {
  display: none;
}
.otel_filter_type label:has(input:checked) {
  color: var(--yellow);
  border-bottom: 2px solid var(--yellow);
}
.otel_filter_type .t16 {
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.03em;
  text-align: center;
  color: var(--dark-blue);
}
.otel_slide .alice-carousel__stage-item {
  max-width: 500px;
}
.otel_slide_item {
  border-radius: 20px;
  border: 1px solid var(--beige);
  padding: 10px;
  width: 97%;
  background: var(--white);
  position: relative;
}
.otel_slide_item_img {
  border-radius: 10px;
  width: 100%;
  height: 17vw;
  background-size: cover !important;
  cursor: pointer;
}
.otel_slide_item_img_type {
  padding: 20px;
}
.otel_slide_item_img_type span {
  font-weight: 400;
  color: #000;
  padding: 5px 15px;
  background: var(--beige);
  text-transform: capitalize;
  width: -moz-min-content;
  width: min-content;
  border-radius: 10px;
}
.otel_slide_item_info {
  padding: 20px 36px;
}
.otel_slide_item_info_name {
  font-weight: 500;
  line-height: 130%;
  text-transform: uppercase;
  color: var(--dark-blue);
  padding-bottom: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: Ellipsis;
}
.otel_slide_item_info_lab {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  font-weight: 400;
  color: var(--dark-blue);
  cursor: pointer;
}
.otel_slide_item_info_lab:hover {
  text-decoration: underline;
  text-decoration-color: var(--yellow);
}
.otel_slide_item_info_lab span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: Ellipsis;
}
.otel_slide_item_popup {
  position: absolute;
  border: 1px solid transparent;
  border-radius: 20px;
  width: 0%;
  height: 0%;
  padding: 0px 0px;
  overflow: hidden;
  background: var(--white);
  left: 0;
  top: 0;
}
.otel_slide_item_popup * {
  opacity: 0;
  transition: opacity 3s;
}
.otel_slide_item_popup.active {
  border: 1px solid var(--beige);
  width: 100%;
  height: 100%;
  padding: 32px 46px;
}
.otel_slide_item_popup.active * {
  transition: opacity 3s;
  opacity: 1;
}
.otel_slide_item_popup .t20 {
  font-weight: 500;
  line-height: 130%;
  text-transform: uppercase;
  color: var(--dark-blue);
}
.otel_slide_item_popup .otel_slide_item_info_lab {
  padding: 20px 0px;
  align-items: start;
}
.otel_slide_item_popup .otel_slide_item_info_lab span {
  text-wrap: wrap;
}
.otel_slide_item_popup_text {
  font-weight: 400;
  font-size: clamp(12px, 1.5vw, 14px);
  line-height: 160%;
  color: var(--dark-blue);
  padding: 10px 0px 10px 0px;
  height: 250px;
}
.otel_slide_item_popup_but {
  padding: 7px 31px;
  border-radius: 30px;
  background: var(--yellow);
  font-weight: 400;
  font-size: 14px;
  color: var(--dark-blue);
  gap: 10px;
  margin-top: 25px;
}

.info {
  padding: 150px 0px;
  background: url(/public/img/info.jpg);
  background-size: cover;
}
.info .h2 {
  color: var(--white);
}
.info_content {
  margin-top: 60px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
.info_content_item {
  background: var(--white);
  min-height: 17vw;
  border-radius: 20px;
  padding: 10px;
}
.info_content_item_img {
  border-radius: 16px;
  width: 100%;
  height: 13vw;
  background-size: cover !important;
  pointer-events: none;
}
.info_content_item .t18 {
  padding-top: 15px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  text-align: center;
  color: var(--dark-blue);
}

.footer {
  background: url(/public/img/footerfon.png);
  background-position: left center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 32px 0px;
}
.footer_img {
  height: 100%;
  width: auto;
}
.footer_img img {
  height: 100%;
  width: auto;
}
.footer .container {
  justify-content: space-between;
}
.footer_nav {
  gap: 75px;
}
.footer_nav:last-child {
  gap: 14px;
}
.footer_linkBox {
  gap: 100px;
  justify-content: space-between;
  min-width: 50%;
}
.footer_link_massege {
  width: 31px;
  height: 31px;
  background: var(--dark-blue);
  border-radius: 50%;
}
.footer_link_massege:hover {
  transform: scale(1.1);
}

.popupInfo {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  background: rgba(137, 137, 137, 0.4509803922);
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  width: 100vw;
  height: 0vh;
  z-index: 999999;
}
.popupInfo.active {
  height: 100vh;
}
.popupInfo_content {
  background: #fff;
  background: var(--white);
  border-radius: 20px;
  height: auto;
  max-height: 890px;
  max-width: 700px;
  padding: 30px;
  width: 100%;
}
.popupInfo_content_img {
  border-radius: 14px 14px 0 0;
  width: 100%;
  min-height: 230px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.popupInfo_content .t20 {
  font-weight: 700;
  line-height: 130%;
  padding: 10px 0px;
  color: var(--dark-blue);
}
.popupInfo_content .otel_slide_item_popup_text {
  overflow-y: auto;
  margin: 24px 0px 10px 0px;
}
.popupInfo_content .otel_slide_item_popup_text::-webkit-scrollbar, .popupInfo_content .otel_slide_item_popup_text::-webkit-scrollbar {
  height: 12px;
  width: 12px;
}
.popupInfo_content .otel_slide_item_popup_text::-webkit-scrollbar-track, .popupInfo_content .otel_slide_item_popup_text::-webkit-scrollbar-track {
  background: white;
}
.popupInfo_content .otel_slide_item_popup_text::-webkit-scrollbar-thumb, .popupInfo_content .otel_slide_item_popup_text::-webkit-scrollbar-thumb {
  background-color: #f5b047;
  border-radius: 5px;
  border: 3px solid white;
}

.map {
  margin: 120px 0px;
}
.map .container {
  flex-direction: column;
  align-items: start;
  gap: 36px;
}
.map_content {
  position: relative;
  height: 500px;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
}
.map_content_map {
  width: 100%;
  height: 500px;
}
.map .h2::after {
  content: "Чтобы ознакомиться с местоположением объекта  на карте коснитесь его названия";
  font-size: clamp(16px, 2.5vw, 18px);
  max-width: 500px;
  line-height: 130%;
  display: block;
  padding-top: 14px;
  font-weight: 400;
}
.map .ymaps-2-1-79-balloon__content {
  min-width: auto !important;
  min-width: 230px !important;
  min-height: 190px !important;
  width: -moz-max-content !important;
  width: max-content !important;
}
.map .ymaps-2-1-79-balloon__content * {
  display: block;
}
.map .ymaps-2-1-79-balloon__content > ymaps {
  width: -moz-max-content !important;
  width: max-content !important;
  height: auto !important;
}
.map .ymaps-2-1-79-balloon__content > ymaps img {
  width: auto !important;
  height: 100px !important;
}
.map .ymaps-2-1-79-balloon__tail {
  box-shadow: none !important;
}

.otziv {
  padding: 120px 0px;
  background: url(/public/img/otziv/fon.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.otziv_menu {
  justify-content: space-between;
  padding-bottom: 60px;
}
.otziv_slider_item {
  display: grid;
  grid-template-columns: 1fr 1.2fr;
  gap: 20px;
  min-height: 470px;
  width: 100%;
  padding: 0 1%;
}
.otziv_slider_item_content {
  padding: 36px 46px;
  border: 1px solid var(--yellow);
  border-radius: 20px;
  height: -moz-min-content;
  height: min-content;
  margin: auto 0px;
  background: var(--white);
}
.otziv_slider_item_content_author {
  font-weight: 700;
  color: var(--dark-blue);
  padding-bottom: 30px;
}
.otziv_slider_item_content_text {
  font-weight: 400;
  line-height: 140%;
  color: var(--dark-blue);
}
.otziv_slider_item_img {
  min-height: 470px;
  width: 100%;
  border-radius: 20px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

@media screen and (max-width: 1650px) {
  .container {
    width: 1400px;
  }
  .test_content {
    width: 700px;
    left: 20%;
  }
}
@media screen and (max-width: 1450px) {
  .container {
    width: 1200px;
  }
  .test_content {
    width: 700px;
    left: 0%;
    height: 40vw;
  }
  .test_img {
    right: 18%;
  }
  .test_content .alice-carousel__stage-item {
    height: 32vw;
  }
}
@media screen and (max-width: 1250px) {
  .container {
    width: 900px;
  }
  .test_content {
    width: 500px;
    left: 0%;
    height: 45vw;
  }
  .test_img {
    right: 18%;
  }
  .test_content .alice-carousel__stage-item {
    height: 35vw;
  }
  .test_img_item_content {
    height: 88%;
  }
  .test_content_item_butt {
    padding: 10px 16px;
    height: 47px;
  }
}
@media screen and (max-width: 1400px) {
  .test_progressBar .progress {
    width: 780px;
  }
  .test_progressBar .steps {
    width: 780px;
  }
  .ekskurs_slider_item .t18 {
    padding: 10px 15px;
  }
}
@media screen and (max-width: 1300px) {
  .restor_menu:nth-child(1) {
    grid-template-columns: 1fr;
    gap: 30px;
  }
  .otziv_slider_item_content {
    padding: 25px;
  }
  .otziv_slider_item {
    grid-template-columns: repeat(1, 1fr);
    padding: 0 3%;
  }
}
@media screen and (max-width: 1200px) {
  .ekskurs_slider_item {
    width: 95%;
  }
  .header_logoBox img {
    height: 50px;
    width: auto;
  }
  .footer {
    padding: 19px 0px;
  }
  .info,
  .ekskurs {
    padding: 120px 0px;
  }
  .info_content {
    margin-top: 60px;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  .footer_nav {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 20px;
  }
  .footer .container {
    gap: 20px;
  }
  .test_progressBar .steps,
  .test_progressBar .progress {
    width: 410px;
  }
  .test_progressBar {
    width: 58%;
    bottom: 0%;
    z-index: 999;
    right: 0;
    justify-content: space-between;
  }
  .test_content_item {
    gap: 7px;
  }
  .test_content {
    padding: 20px 30px;
  }
  .test_content_item {
    gap: 15px;
  }
  .test_content_item_butt {
    padding: 12px 16px;
    height: auto;
  }
  .test .container {
    display: grid;
    grid-template-columns: 1fr;
    height: 100%;
    position: relative;
  }
  .test_content {
    background: rgba(0, 49, 77, 0.6705882353);
    border-radius: 0px;
    width: 100%;
    height: 100%;
    min-height: auto;
    padding: 30px 60px;
    position: relative;
    left: 0;
    bottom: 0;
    background-image: url(/public/img/test/fon2.png);
    background-position: bottom right;
    background-repeat: no-repeat;
    max-width: 100%;
    overflow: hidden;
    z-index: 5;
  }
  .test_img_item {
    position: relative;
    width: 102%;
    height: 45vw;
    background-size: cover !important;
    border-radius: 25px;
  }
  .test_img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: visible;
  }
  .test_img .alice-carousel__wrapper {
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    box-sizing: border-box;
    width: 100%;
    height: auto;
  }
  .test_img_item {
    position: relative;
    background-size: 120% auto !important;
    background-position: -80px center !important;
    background-repeat: no-repeat !important;
    border-radius: 0px;
  }
  .test_img_item_content {
    padding-left: 25px;
    width: 76vw;
    height: 80%;
    overflow: scroll;
  }
  .test_img_item.otvets {
    padding: 0.5%;
  }
}
@media screen and (max-width: 1000px) {
  .footer .container {
    gap: 15px;
  }
  .footer_linkBox {
    gap: 30px;
  }
  .hello_content {
    gap: 20px;
  }
  .test {
    overflow: hidden;
  }
}
@media screen and (max-width: 970px) {
  .container {
    width: 700px;
  }
  .test_img_item_content {
    width: 690px;
  }
  li.test_img_item_content_item {
    padding: 10px 20px;
  }
  .test_img_item_content {
    padding-left: 40px;
  }
  h3.test_img_item_title {
    padding: 20px 20px 20px 20px;
  }
  li.alice-carousel__stage-item.__active.__target {
    width: 700px !important;
  }
  .test .container {
    border-radius: 20px;
    overflow: hidden;
    border: 3px solid #f5b047;
  }
  .header_navigate {
    gap: 20px;
  }
  .t16 {
    font-size: clamp(10px, 2vw, 12px);
  }
  ul.test_img_item_content {
    padding-bottom: 30px;
  }
  .test {
    background: url(http://localhost:3001/static/media/fon.01422f8….png);
    margin: 80px 0px 0px 0px;
    z-index: 1;
    height: 55vw;
    background-position: center bottom !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    position: relative;
  }
  .test_img_item {
    width: 100%;
    height: 56vw;
  }
  .test_content .alice-carousel__stage-item {
    height: 50vw;
  }
}
@media screen and (max-width: 750px) {
  .container {
    width: 90%;
  }
  .test ._butN {
    display: block;
    z-index: 9999;
  }
  li.alice-carousel__stage-item.__active.__target {
    width: 100% !important;
  }
  .test_img_item_content {
    width: 100%;
  }
  .test_img_item.otvets {
    width: 100%;
  }
  .oKrasnoyarsk {
    margin: 80px 0;
  }
  .oKrasnoyarsk .container {
    gap: 20px;
  }
  .header_navigate {
    flex-direction: column;
    gap: 25px;
    display: none;
  }
  .footer .container {
    flex-direction: column;
  }
  .info_content {
    grid-template-columns: repeat(1, 1fr);
  }
  .info_content_item_img {
    height: 53vw;
  }
  .ekskurs_hed,
  .footer_linkBox,
  .otel_filter,
  .oKrasnoyarsk_menu {
    flex-direction: column;
    align-items: start;
    gap: 20px;
  }
  .restor_menu {
    grid-template-columns: 1fr;
    gap: 20px;
  }
  .otel_filter_type {
    flex-wrap: wrap;
    justify-content: start;
  }
  .test {
    height: 120vw;
  }
  .otel_slide_item_img {
    height: 53vw;
  }
  .restor_slide .alice-carousel__stage-item {
    height: 536px;
  }
  .test_content .alice-carousel__stage-item {
    width: 101% !important;
    min-width: 101% !important;
    max-width: 101% !important;
    height: 70vw;
  }
  .test_progressBar_step {
    display: none;
  }
  .test_progressBar {
    justify-content: end;
  }
  .test_img_item {
    height: 118vw;
  }
  .test_img_item {
    position: relative;
    background-size: 120% 83vw !important;
    background-position: -80px -21px !important;
    background-repeat: no-repeat !important;
    border-radius: 0px;
  }
  .header .wite {
    position: relative;
    bottom: 0px;
  }
  .hello_contact {
    gap: 10px;
    position: absolute;
    z-index: 5;
    bottom: 10px;
  }
  .hello_content_name {
    display: none;
  }
  .header_logoBox img {
    height: 30px;
  }
  .hello_content_but {
    padding: 10px 17px;
  }
  .oKrasnoyarsk_slider_item {
    padding: 23px;
    width: 99%;
    padding: 0 1%;
    max-width: none;
  }
  .oKrasnoyarsk_menu_switch {
    align-self: flex-end;
  }
  .oKrasnoyarsk_slider_item_name {
    padding-bottom: 25px;
  }
  .hello {
    display: none;
  }
  .hello.mob {
    height: 150vw;
    display: block;
  }
  .hello.mob .bg_2 {
    height: 170vw;
  }
  .hello.mob .hello_fon.bg_4 {
    top: auto;
    bottom: -9vw;
    z-index: 5;
    overflow: hidden;
    width: 100%;
    animation: 5s strelAnim ease;
    height: 100vw;
  }
  .hello.mob .hello_fon.bg_4 img {
    width: 110vw;
    height: 180vw;
    min-width: 99vw;
    min-height: 49vw;
    position: absolute;
    bottom: -23vw;
    left: -7vw;
    pointer-events: none;
  }
  .hello_fon.bg_1,
  .hello_fon.bg_3 {
    width: auto;
    left: -120%;
  }
  .menu {
    display: block;
  }
  .header_navigate.mob {
    display: flex;
    position: absolute;
    background: #fff;
    width: 100%;
    height: 100vh;
    top: 0vh;
    animation: 1s mob;
    left: 0;
    justify-content: center;
  }
  @keyframes mob {
    0% {
      top: -100vh;
    }
    100% {
      top: 0vh;
    }
  }
  .header_logoBox {
    height: 100%;
    z-index: 9;
  }
  .test_img_item {
    position: relative;
    background-size: cover !important;
    height: 157vw;
    width: 125%;
  }
  .test_img_item.otvets {
    background: #fff !important;
  }
}
@media screen and (max-width: 475px) {
  .test_content .alice-carousel__stage-item {
    width: 101% !important;
    min-width: 101% !important;
    max-width: 101% !important;
    height: 90vw;
  }
  .restor_menu_chek_list {
    flex-wrap: wrap;
    justify-content: start;
  }
  .restor_slide_item_img {
    height: 127px;
  }
  .restor {
    padding: 80px 0px;
  }
  .restor_slide .alice-carousel__stage-item {
    height: auto;
  }
}
@media screen and (max-width: 375px) {
  .test_content .alice-carousel__stage-item {
    height: 117vw;
  }
  .test_content {
    padding: 19px 21px;
  }
  .test_img_item_content {
    height: 59%;
  }
}/*# sourceMappingURL=style.css.map */