:root {
    --dark-blue: #00314d;
    --blue: #009fe3;
    --beige: #f8e8dd;
    --yellow: #f5ab3d;
    --white: #fff;
    --red: #e45c54;
    --font-family: "Gotham Pro", sans-serif;
    --second-family: "Geometria", sans-serif;
}
html {
    scroll-behavior: smooth;
    scroll-padding: 100px;
}
.main {
    overflow-x: hidden;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    font-family: var(--font-family);
    transition: 300ms;
}
body{
    &.fixed{
        overflow: hidden;
        height: 100vh;

    }
}
a {
    color: #000;
}
button {
    border: none;
    cursor: pointer;
}
.h2 {
    font-weight: 700;
    // font-size: 48px;
    font-size: clamp(23px, 3.5vw, 48px);
    line-height: 120%;
    color: var(--dark-blue);
}

.container {
    width: 1600px;
    margin: 0 auto;
    max-width: 1800px;
}
.t16 {
    font-size: clamp(14px, 2vw, 16px);
}
.t18 {
    font-size: clamp(16px, 2.5vw, 18px);
}
.t20 {
    font-size: clamp(18px, 3vw, 20px);
}
.flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.header:has(.header_navigation.active) {
    .btn1 .icon-right:before,
    .btn1 .icon-right::after,
    .btn1 .icon-left:before,
    .btn1 .icon-left::after {
        background-color: var(--dark-blue);
    }
}
$dark: #212121;
$light: #efefef;
$color: #b3e5fc;
.menu {
    display: none;
}

.btn1 {
    position: relative;
    width: 60px;
    height: 60px;
    transition-duration: 0.5s;
    z-index: 99999;
    .icon-left {
        transition-duration: 0.5s;
        position: absolute;
        height: 3px;
        width: 15px;
        top: 30px;
        background-color: var(--dark-blue);
        left: 0px;

        &:before {
            transition-duration: 0.5s;
            position: absolute;
            width: 15px;
            height: 3px;
            background-color: var(--dark-blue);
            content: "";
            top: -10px;
        }

        &:after {
            transition-duration: 0.5s;
            position: absolute;
            width: 15px;
            height: 3px;
            background-color: var(--dark-blue);
            content: "";
            top: 10px;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .icon-right {
        transition-duration: 0.5s;
        position: absolute;
        height: 3px;
        width: 15px;
        top: 30px;
        background-color: var(--dark-blue);
        left: 15px;

        &:before {
            transition-duration: 0.5s;
            position: absolute;
            width: 15px;
            height: 3px;
            background-color: var(--dark-blue);
            content: "";
            top: -10px;
        }

        &:after {
            transition-duration: 0.5s;
            position: absolute;
            width: 15px;
            height: 3px;
            background-color: var(--dark-blue);
            content: "";
            top: 10px;
        }
    }

    &.open {
        .icon-left {
            transition-duration: 0.5s;
            background: transparent;

            &:before {
                transform: rotateZ(45deg) scaleX(1.4)
                    translate(0px, -1px);
            }

            &:after {
                transform: rotateZ(-45deg) scaleX(1.4)
                    translate(4px, -5px);
            }
        }

        .icon-right {
            transition-duration: 0.5s;
            background: transparent;

            &:before {
                transform: rotateZ(-45deg) scaleX(1.4)
                    translate(-1px, -1px);
            }

            &:after {
                transform: rotateZ(45deg) scaleX(1.4)
                    translate(-5px, -5px);
            }
        }
    }
    &:hover {
        cursor: pointer;
    }
}

.header {
    padding: 13px 0;
    height: 90px;
    position: fixed;
    width: 100vw;
    z-index: 100;
    top: 0;
    left: 0;
    &_logoBox {
        height: 100%;
        img {
            height: 100%;
            width: auto;
        }
    }
    .wite {
        position: relative;
        bottom: -20px;
    }
    .dark {
        display: none;
    }
    &.active.burg {
        top: -90px;
    }
    &.active,
    &.burg {
        .dark {
            display: block;
        }
        .wite {
            display: none;
        }
    }
    &.active {
        top: 0px;
        background: var(--white);
    }
    &.burg {
        background: var(--white);
    }
    .container {
        justify-content: space-between;
    }
    &_navigate {
        justify-content: space-between;
        gap: 75px;
    }
    &_link {
        font-weight: 700;
        text-wrap: nowrap;
        color: var(--dark-blue);
        &:hover {
            color: var(--yellow);
            text-decoration: underline;
        }
    }
}
.hello {
    z-index: 1;
    // background: url(/public/img/hello/fon1.jpg);
    height: 49vw;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    position: relative;
    // overflow: hidden;
    display: flex;
    align-items: flex-end;
    &.load {
        * {
            display: none;
        }
    }
    &.mob {
        display: none;
    }
    &_fon {
        position: absolute;
        left: 0;
        top: 0;

        &.bg_1,
        &.bg_3 {
            width: 100%;
            height: 100%;
        }

        &.bg_1 {
            z-index: 4;
        }
        &.bg_2 {
            z-index: 3;
            height: 45vw;
            animation: 3s fonAnim ease;
        }
        &.bg_3 {
            z-index: 2;
        }
        &.bg_4 {
            top: auto;
            bottom: -66vw;
            z-index: 5;
            overflow: hidden;
            width: 100%;
            animation: 5s strelAnim ease;
            height: 100vw;

            img {
                width: 107vw;
                height: 124vw;
                min-width: 99vw;
                min-height: 49vw;
                position: absolute;
                bottom: -4vw;
                left: -4vw;
                pointer-events: none;
            }
        }
        @keyframes fonAnim {
            0% {
                top: 100%;
                opacity: 0;
            }
            30% {
                top: 100%;
                opacity: 0;
            }
            100% {
                top: 0%;
                opacity: 1;
            }
        }
        @keyframes strelAnim {
            0% {
                height: 0vw;
            }

            100% {
                height: 188vw;
            }
        }
    }
    &_content {
        width: 100%;
        z-index: 5;
        position: absolute;
        bottom: 33%;
        flex-direction: column;
        gap: 50px;
        @media screen and (max-width: 1250px) {
            bottom: 25%;
        }
        &_name {
            font-weight: 500;
            font-size: clamp(18px, 2.5vw, 32px);
            color: var(--white);
            gap: 7px;
            align-self: baseline;
            padding-left: 8%;
            img {
                width: 32px;
                height: 32px;
            }
        }
        &_but {
            font-weight: 500;
            color: var(--dark-blue);
            padding: 16px 32px;
            border-radius: 60px;
            background: var(--beige);
            &:hover {
                background: var(--yellow);
                color: var(--white);
            }
        }
        animation: 3s contentAnim ease;

        @keyframes contentAnim {
            0% {
                opacity: 0;
            }
            75% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }
    }
    &_contact {
        gap: 10px;
        position: absolute;
        z-index: 5;
        bottom: 20px;
        right: 10%;
        width: min-content;
        overflow: hidden;
        &_item {
            opacity: 0.5;
            width: 36px;
            height: 36px;
            border-radius: 100%;
            background: var(--white);
            position: relative;
            &:hover {
                opacity: 1;
            }
            &:first-child {
                animation: 2s contactAnim ease-in;
            }
            &:last-child {
                animation: 3.5s contactAnim ease-in;
            }
        }
        @keyframes contactAnim {
            0% {
                bottom: -36px;
            }
            70% {
                bottom: -36px;
            }
            100% {
                bottom: 0px;
            }
        }
    }
}

.preloader {
    // height: 100vh;
    .page {
        // padding: 40px;
         
      }
    
      
      #splash {
        background: #ffffff80;
        background-repeat: repeat-y;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        &.active{
            animation: splash 3s ease-in;
            animation-fill-mode: forwards;
            -webkit-animation-fill-mode: forwards;
        }

        z-index: 999999;
      }
      
      #loader {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%,0);
      }
      
      #loader:after {
        content: '';
        position: absolute;
        left: 50%;
        margin-left: -8px;
        bottom: -170px;
        width: 3px;
        background: #fff;
        background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
        height: 200px;
      }
      
      #loader:before {
        content: '';
        position: absolute;
        left: 50%;
        margin-left: 8px;
        bottom: -190px;
        width: 3px;
        background: #000;
        background: linear-gradient(to bottom, rgba(0,0,0,.2) 0%, rgba(0,0,0,.2) 50%, rgba(0,0,0,0) 100%);
        height: 200px;
      }
      
      #splash .anim {
        height: 100%;
        position: absolute;
        left: 50%;
        width: 100px;
        transform: translate(-50%,100%);
        &.active{
            animation: loader 4s linear;
            animation-fill-mode: forwards;
            -webkit-animation-fill-mode: forwards;
        }
        
      }
      
      @keyframes loader {
        0% {
          transform: translate(-50%,110%);
        }
        30% {
          transform: translate(-50%,50%);
        }
        100% {
          transform: translate(-50%,0%);
        }
      }
      
      @keyframes splash {
        0% {
          transform: translate(0%,0%);
        background: #ffffff80;

        }
        50% {
          transform: translate(0%,0%);

        }
        80%{
            background: #ffffff00;

        }
        100% {
            background: transparent;
          transform: translate(0%,-100%);
        }
      }
      

}
.oKrasnoyarsk {
    margin: 150px 0;
    position: relative;
    z-index: 10;
    .alice-carousel__stage-item {
        // min-width: 320px;
    }
    .container {
        flex-direction: column;
        align-items: stretch;
        gap: 36px;
    }
    &_menu {
        justify-content: space-between;
        width: 100%;
        &_text {
            color: var(--dark-blue);
            font-weight: 400;
            max-width: 636px;
        }

        &_switch {
            gap: 6px;
            &_item {
                width: 50px;
                height: 50px;
                border-radius: 100%;
                border: 1px solid var(--yellow);
                cursor: pointer;
                position: relative;
                svg {
                    fill: #fff;
                }
                background: var(--yellow);
                position: relative;
                &:hover {
                    transform: scale(1.1);
                }
                &:active {
                    transform: scale(1);
                }
                &.inactive {
                    background: #fff;
                    pointer-events: none;
                    svg {
                        fill: #f5ab3d;
                    }
                }
            }
        }
    }

    &_slider {
        width: 100%;
        max-width: 100%;
        height: 370px;
        gap: 20px;
        margin-top: 60px;
        // overflow: hidden;
        & .alice-carousel__stage-item {
        }
        &_item {
            padding: 23px;
            width: 95%;
            max-width: 300px;
            height: 370px;
            background-repeat: no-repeat !important;
            background-size: cover !important;
            background-position: center !important;
            align-items: flex-end;
            border-radius: 19px;
            cursor: pointer;
            overflow: hidden;
            position: relative;
            &::after,
            &::before {
                transition: 300ms;
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
                top: 0;
                opacity: 0;
                background: #f5ab3d69;
            }
            &::after {
            }
            &::before {
                left: 100%;
            }

            &_name {
                font-weight: 500;
                line-height: 120%;
                letter-spacing: -0.03em;
                text-transform: uppercase;
                text-align: center;
                color: var(--white);
                z-index: 10;
                position: relative;
                bottom: 0;
                height: fit-content;
            }
            &:hover {
                &::after {
                    opacity: 1;
                }

                .oKrasnoyarsk_slider_item_name {
                    bottom: 40%;
                    text-shadow: 1px 1px 3px black;
                }
            }
        }
    }
}
.test {
    ._butN{
        display: none;
        position: absolute;
        width: 15px;
        border: 2px solid #000;
        height: 15px;
        right: 52px;
        top: 30px;
        border-bottom: none;
        border-left: none;
        transform: rotate(45deg);
        cursor: pointer;   
        animation: st1 2s infinite;
    }
    @keyframes st1 {
        0%{
            right: 52px;

        }
        50%{
            right: 32px;

        }
        100%{
            right: 52px;

        }
    }
    background: url(/public/img/test/fon.webp);
    margin: 140px 0px 0px 0px;
    z-index: 1;
    height: 45vw;
    background-position: center bottom !important; // изменил это
    background-repeat: no-repeat !important;
    background-size: cover !important;
    position: relative;
    .container {
        position: relative;
    }
    &_progressBar {
        position: absolute;
        height: 50px;
        width: 78%;
        bottom: 0%;
        z-index: 999;
        right: 0;
        justify-content: space-between;
        &.noact {
            display: none;
        }
        &_check {
            font-weight: 500;
            font-size: 45px;
            width: min-content;
            text-shadow: 0px 0px 3px #fff;
            color: var(--dark-blue);
        }

        &_step {
            position: relative;
            z-index: 1;
            width: 90%;
            margin-right: 20px;
        }
        $prog: 1100px;
        .steps {
            position: relative;
            display: flex;
            justify-content: space-between;
            width: $prog;
        }
        .step {
            width: 20px;
            height: 20px;
            background: #fff;
            border: 2px solid #acaca6;
            border-radius: 50%;
            transition: background 1s;
            pointer-events: none;
        }
        .step.selected {
            border: 2px solid var(--yellow);
        }
        .step.completed {
            border: 2px solid var(--yellow);
            background: var(--yellow);
        }
        .progress {
            position: absolute;
            width: $prog;
            height: 50%;
            border-bottom: 2px solid #acaca6;
            z-index: -1;
            // overflow: hidden;
        }
        .percent {
            position: absolute;
            width: 0;
            max-width: 100%;
            height: 100%;
            border-bottom: 3px solid var(--yellow);
            z-index: 1;
            transition: width 1s;
            bottom: -3px;
        }
    }
    .h2 {
        color: #fff;
        font-size: clamp(16px, 1.8vw, 48px);
        line-height: 120%;
    }
    .container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 100%;
        align-items: end;
    }
    &_content {
        background: var(--dark-blue);
        border-radius: 20px;
        width: 800px;
        height: 32vw;

        min-height: 58%;
        padding: 50px 60px;
        position: relative;
        left: 29%;
        bottom: -2%;

        background-image: url(/public/img/test/fon2.png);
        background-position: bottom right;
        background-repeat: no-repeat;
        max-width: 100%;
        overflow: hidden;
        z-index: 5;
        &.ot {
            z-index: 4;
        }
        .alice-carousel__stage-item {
            width: 101% !important;
            min-width: 101% !important;
            max-width: 101% !important;
            height: 26vw;
        }
        &_item {
            flex-direction: column;
            align-items: flex-start;
            gap: 35px;
            height: 100%;

            &_butBox {
                gap: 10px;
            }
            &_text {
                font-weight: 400;
                line-height: 120%;
                color: var(--white);
                font-size: clamp(12px, 1vw, 20px);

                li {
                    padding-bottom: 20px;
                    display: block;
                    max-width: 70%;
                    // &::before{
                    //     content: "";
                    //     display: inline;
                    //     padding-left: 20px;
                    // }
                }
            }

            &_butt {
                border-radius: 60px;
                padding: 16px 35px;
                width: min-content;
                height: 47px;
                background: var(--yellow);
                color: var(--white);
                border: 1px solid var(--yellow);
                justify-content: space-between;
                cursor: pointer;
                text-wrap: nowrap;
                display: flex;
                gap: 30px;
                opacity: 1;
                margin-top: auto;
                margin-bottom: 25px;
                &.onActive {
                    pointer-events: none;
                    opacity: 0.5;
                }
                svg {
                    fill: var(--white);
                }
                &:hover {
                    color: var(--yellow);
                    background: var(--white);
                    svg {
                        fill: var(--yellow);
                    }
                }
            }

            &_boxOpr {
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;
                label {
                    cursor: pointer;
                    display: flex;
                    gap: 20px;
                    &:hover {
                        text-decoration: underline;
                        text-decoration-color: var(--white);
                    }
                    & div {
                        content: "";
                        width: 20px;
                        height: 20px;
                        min-width: 20px;
                        min-height: 20px;
                        border-radius: 3px;
                        border: 2px solid #fff;
                        display: block;
                        background: var(--white);
                    }
                    input {
                        display: none;
                    }
                    &:has(input:checked) {
                        & div {
                            background: var(--blue);
                        }
                    }
                }
            }
        }
    }

    &_img {
        width: 45vw;
        height: 45vw;
        position: relative;
        overflow: hidden;
        z-index: 4;
        bottom: -2%;
        .alice-carousel__stage-item {
            opacity: 0;
        }
        .alice-carousel__stage-item.__target {
            opacity: 1;
        }
        &_item {
            position: relative;
            width: 45vw;
            height: 45vw;
            background-size: contain !important;
            background-position: center center !important;
            background-repeat: no-repeat !important;
            border-radius: 25px;

            &.otvets {
                padding: 7.5%;
              
            }
            &_title {
                font-weight: 700;
                font-size: clamp(24px, 3vw, 32px);
                line-height: 130%;
                padding-bottom: 40px;
                -webkit-text-stroke: 1px var(--dark-blue);
                color: white;
                text-shadow: 2px 2px 0 #000,
                    -1px -1px 0 #000, 1px -1px 0 #000,
                    -1px 1px 0 #000, 1px 1px 0 #000;
            }

            &_content {
                padding-left: 25px;
                overflow-y: auto;
                height: 90%;
                scrollbar-width: thin;
                scrollbar-color: #f5b047 white;

                &::-webkit-scrollbar,
                &::-webkit-scrollbar {
                    height: 12px;
                    width: 12px;
                }
                &::-webkit-scrollbar-track,
                &::-webkit-scrollbar-track {
                    background: white;
                }
                &::-webkit-scrollbar-thumb,
                &::-webkit-scrollbar-thumb {
                    background-color: #f5b047;
                    border-radius: 5px;
                    border: 3px solid white;
                }
                &_item {
                    padding-bottom: 35px;
                    list-style-type: auto;
                    font-weight: bold;
                    &_link {
                        color: var(--dark-blue);
                        text-decoration: underline;
                    }
                    &_mest {
                        line-height: 120%;
                        letter-spacing: -0.03em;
                        text-transform: uppercase;
                        text-decoration: underline;
                        text-decoration-skip-ink: none;
                        color: var(--dark-blue);
                        padding-bottom: 20px;
                        display: block;
                        cursor: pointer;
                    }

                    &_opis {
                        font-weight: 400;
                        line-height: 120%;
                        color: var(--dark-blue);
                    }
                }
            }
        }
    }
}
body.poapActive {
    overflow: hidden; // изменил это
    padding-right: 16px;
}
.ekskurs {
    padding: 150px 0px;
    background: var(--beige);
    z-index: 1;
    background: url(/public/img/ekskurs/fon.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    &_hed {
        align-items: flex-end;
        justify-content: space-between;
        width: 100%;
        padding: 36px 0px 60px 0px;

        &_filter {
            align-items: start;

            gap: 15px;
            label {
                display: block;
                font-weight: 400;
                line-height: 130%;
                letter-spacing: -0.03em;
                text-align: right;
                color: var(--white);
                cursor: pointer;
                text-wrap: nowrap;
                &:hover {
                    text-decoration: underline;
                }
                input {
                    display: none;
                }
                width: min-content;
                &:has(input:checked) {
                    color: var(--yellow);
                    text-decoration: underline;
                }
            }
            .t16 {
                font-weight: 400;
                line-height: 130%;
                letter-spacing: -0.03em;
                text-align: center;
                color: var(--dark-blue);
            }
        }
        .t18 {
            font-weight: 500;
            line-height: 120%;
            letter-spacing: -0.03em;
            text-transform: uppercase;
            text-align: center;
            color: var(--dark-blue);
        }
    }

    &_slider {
        width: 100%;
        // .alice-carousel__stage-item {
        //     max-width: 385px;
        //     min-width: 385px;
        // }
        &_item {
            background: var(--white);
            border-radius: 20px;
            width: 90%;
            cursor: pointer;
            // background-size: cover !important;
            background-repeat: no-repeat !important;
            background-position: center center !important;
            background-size: auto 100% !important;

            height: 300px;
            align-items: flex-end;
            // &_img{
            //     border-radius: 16px 16px 0 0;
            //     width: 100%;
            //     background-size: cover !important;
            //     background-repeat: no-repeat !important;
            //     background-position: center center !important;

            // }
            overflow: hidden;

            .t18 {
                font-weight: 500;
                line-height: 120%;
                letter-spacing: -0.03em;
                text-transform: uppercase;
                text-align: center;
                color: var(--dark-blue);
                padding: 12px 40px;
                height: 25%;
                // height: fit-content;
                background: var(--white);
                width: 100%;
                transform: 500ms;

                &::after {
                    content: attr(data-text);
                }
            }
            &:hover {
                .t18 {
                    height: 100%;
                    background: #ffffff96;
                    backdrop-filter: blur(5px);
                    &::after {
                        content: attr(data-hover);
                    }
                }
            }
        }
    }
}

.restor {
    background-color: var(--dark-blue);
    background: url(/public/img/restor/fon.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 150px 0px;
    .h2 {
        color: var(--white);
        max-height: 630px;
        text-align: start;
    }
    .container {
        flex-direction: column;
        gap: 60px;
    }
    &_menu {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        gap: 150px;

        &-left {
            &_switch {
                gap: 25px;
                justify-content: flex-start;
                align-items: start;
                &_item {
                    width: 100px;
                    cursor: pointer;
                    flex-direction: column;
                    gap: 10px;
                    input {
                        display: none;
                    }
                    &_img {
                        width: 92px;
                        height: 75px;
                        background: var(--white);
                        padding: 10px;
                        border-radius: 5px;
                        img {
                            height: auto;
                            width: 100%;
                            filter: brightness(0)
                                saturate(100%) invert(10%)
                                sepia(30%) saturate(6732%)
                                hue-rotate(185deg)
                                contrast(101%);
                        }
                    }
                    &_text {
                        font-weight: 400;
                        line-height: 130%;
                        letter-spacing: -0.03em;
                        text-align: start;
                        // color: var(--white);
                        width: 100%;
                    }
                    &:has(input:checked) {
                        .restor_menu-left_switch_item_img {
                            background: var(--yellow);

                            img {
                                filter: brightness(0)
                                    invert(100%) sepia(100%)
                                    saturate(0%)
                                    hue-rotate(285deg)
                                    contrast(102%);
                            }
                        }
                    }
                }
            }
        }
        &_chek {
            flex-direction: column;
            align-items: start;
            width: 100%;
            gap: 30px;

            .t18 {
                font-weight: 500;
                line-height: 120%;
                letter-spacing: -0.03em;
                text-transform: uppercase;
                color: var(--white);
            }

            &_list {
                align-items: start;

                gap: 15px;
                label {
                    display: block;
                    font-weight: 400;
                    line-height: 130%;
                    letter-spacing: -0.03em;
                    text-align: right;
                    color: var(--white);
                    cursor: pointer;
                    text-wrap: nowrap;
                    &:hover {
                        text-decoration: underline;
                    }
                    input {
                        display: none;
                    }
                    width: min-content;
                    &:has(input:checked) {
                        color: var(--yellow);
                        text-decoration: underline;
                    }
                }
            }
        }
        &_switchSlider {
            .oKrasnoyarsk_menu_switch {
                justify-content: flex-end;
            }
        }
    }

    &_slide {
        width: 100%;
        .alice-carousel__wrapper {
            overflow: visible;
        }
        .alice-carousel__stage-item {
            // width: 448px;
            // min-width: 440px;
            height: 536px;
            max-width: 500px;
        }

        &_ops {
        }
        &_item {
            overflow: hidden;
            background: var(--white);
            border-radius: 20px;
            margin: auto;
            width: 97%;
            &_box {
                padding: 10px;
            }
            &_img {
                // background: url(/public/img/restor/slide/1.png);
                background-size: cover !important;
                background-repeat: no-repeat;
                border-radius: 16px;
                position: relative;
                width: 100%;
                height: 327px;

                &_flags {
                    width: 100%;
                    justify-content: space-between;
                    padding: 10px;
                    align-items: flex-start;

                    .t16 {
                        font-weight: 400;
                        color: #000;
                        border-radius: 10px;
                        padding: 7px 22px;
                        background: var(--beige);
                    }
                    &_type {
                        flex-direction: column;
                        gap: 10px;
                        &_item {
                            background: var(--yellow);
                            padding: 5px;
                            border-radius: 5px;
                            width: 57px;
                            height: 46px;
                            img {
                                width: 100%;
                                height: auto;
                                filter: brightness(0)
                                    invert(100%) sepia(100%)
                                    saturate(0%)
                                    hue-rotate(285deg)
                                    contrast(102%);
                            }
                        }
                    }
                }
            }

            &_name {
                font-weight: 700;
                text-transform: uppercase;
                color: var(--dark-blue);
                margin-bottom: 15px;
            }
            &_info {
                flex-direction: column;
                align-items: start;
                padding: 15px;
                padding-left: 30px;

                &_item {
                    flex-direction: column;
                    align-items: start;
                    gap: 8px;
                    &_lab {
                        display: flex;
                        align-items: flex-start;
                        gap: 8px;
                        min-height: 30px;
                        justify-content: flex-start;
                        cursor: pointer;
                        &:hover {
                            text-decoration: underline;
                            text-decoration-color: var(
                                --yellow
                            );
                        }
                        color: var(--dark-blue);
                        .kuh:not(:last-child)::after {
                            content: ", ";
                        }
                        img {
                            padding-top: 4px;
                        }
                    }
                }
            }

            &_footer {
                background: #f8e8dd7d;
                width: 100%;
                justify-content: start;
                padding: 14px 35px;
                height: 56;
            }
        }
    }
}
body {
    overflow-x: hidden;
}
.otel {
    margin: 120px 0px;

    &_filter {
        padding: 36px 0px;
        justify-content: space-between;
        &_type {
            gap: 20px;
            label {
                cursor: pointer;
                padding: 3px 5px;
                text-align: center;
                border-bottom: 2px solid var(--white);

                input {
                    display: none;
                }

                &:has(input:checked) {
                    color: var(--yellow);

                    border-bottom: 2px solid var(--yellow);
                }
            }
            .t16 {
                font-weight: 400;
                line-height: 130%;
                letter-spacing: -0.03em;
                text-align: center;
                color: var(--dark-blue);
            }
        }
    }

    &_slide {
        .alice-carousel__stage-item {
            max-width: 500px;
        }
        &_item {
            border-radius: 20px;
            border: 1px solid var(--beige);
            padding: 10px;
            width: 97%;
            background: var(--white);
            position: relative;

            &_img {
                border-radius: 10px;
                width: 100%;
                height: 17vw;
                background-size: cover !important;
                cursor: pointer;

                &_type {
                    padding: 20px;

                    span {
                        font-weight: 400;
                        color: #000;
                        padding: 5px 15px;
                        background: var(--beige);
                        text-transform: capitalize;
                        width: min-content;
                        border-radius: 10px;
                    }
                }
            }
            &_info {
                padding: 20px 36px;
                &_name {
                    font-weight: 500;
                    line-height: 130%;
                    text-transform: uppercase;
                    color: var(--dark-blue);
                    padding-bottom: 20px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: Ellipsis;
                }
                &_lab {
                    display: flex;
                    align-items: center;
                    gap: 20px;

                    width: 100%;
                    font-weight: 400;
                    color: var(--dark-blue);
                    cursor: pointer;
                    &:hover {
                        text-decoration: underline;
                        text-decoration-color: var(
                            --yellow
                        );
                    }
                    span {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: Ellipsis;
                    }
                }
            }

            &_popup {
                position: absolute;
                border: 1px solid transparent;
                border-radius: 20px;
                width: 0%;
                height: 0%;
                padding: 0px 0px;
                overflow: hidden;
                background: var(--white);
                left: 0;
                top: 0;
                * {
                    opacity: 0;
                    transition: opacity 3s;
                }
                &.active {
                    border: 1px solid var(--beige);
                    * {
                        transition: opacity 3s;

                        opacity: 1;
                    }
                    width: 100%;
                    height: 100%;
                    padding: 32px 46px;
                }
                .t20 {
                    font-weight: 500;
                    line-height: 130%;
                    text-transform: uppercase;
                    color: var(--dark-blue);
                }
                .otel_slide_item_info_lab {
                    padding: 20px 0px;
                    align-items: start;
                    span {
                        text-wrap: wrap;
                    }
                }

                &_text {
                    font-weight: 400;
                    font-size: clamp(12px, 1.5vw, 14px);

                    line-height: 160%;
                    color: var(--dark-blue);
                    padding: 10px 0px 10px 0px;
                    height: 250px;
                }

                &_but {
                    padding: 7px 31px;
                    border-radius: 30px;
                    background: var(--yellow);
                    font-weight: 400;
                    font-size: 14px;
                    color: var(--dark-blue);
                    gap: 10px;
                    margin-top: 25px;
                }
            }
        }
    }
}

.info {
    padding: 150px 0px;
    background: url(/public/img/info.jpg);
    background-size: cover;
    // background-attachment: fixed;
    // background-repeat: no-repeat;
    .h2 {
        color: var(--white);
    }

    &_content {
        margin-top: 60px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;

        &_item {
            background: var(--white);
            min-height: 17vw;

            border-radius: 20px;
            padding: 10px;
            &_img {
                border-radius: 16px;
                width: 100%;
                height: 13vw;
                background-size: cover !important;
                pointer-events: none;
            }

            .t18 {
                padding-top: 15px;
                font-weight: 500;
                line-height: 120%;
                letter-spacing: -0.03em;
                text-transform: uppercase;
                text-align: center;
                color: var(--dark-blue);
            }
        }
    }
}
.footer {
    background: url(/public/img/footerfon.png);
    background-position: left center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 32px 0px;

    &_img {
        height: 100%;
        width: auto;
        img {
            height: 100%;
            width: auto;
        }
    }
    .container {
        justify-content: space-between;
    }
    &_nav {
        gap: 75px;

        &:last-child {
            gap: 14px;
        }
    }
    &_linkBox {
        gap: 100px;
        justify-content: space-between;
        min-width: 50%;
    }
    &_link {
        &_massege {
            width: 31px;
            height: 31px;
            background: var(--dark-blue);
            border-radius: 50%;

            &:hover {
                transform: scale(1.1);
            }
        }
    }
}

.popupInfo {
    position: fixed;
    left: 0;
    top: 0;
    overflow: hidden;
    background: #89898973;
    backdrop-filter: blur(3px);
    width: 100vw;
    height: 0vh;

    z-index: 999999;
    &.active {
        height: 100vh;
    }
    &_content {
        background: #fff;
        background: var(--white);
        border-radius: 20px;
        height: auto; // изменил это
        max-height: 890px;
        max-width: 700px;
        padding: 30px;
        width: 100%;
        &_img {
            border-radius: 14px 14px 0 0;
            width: 100%;
            min-height: 230px;

            background-repeat: no-repeat !important;
            background-size: cover !important;
        }
        .t20 {
            font-weight: 700;
            line-height: 130%;
            padding: 10px 0px;
            color: var(--dark-blue);
        }

        .otel_slide_item_popup_text {
            overflow-y: auto;
            margin: 24px 0px 10px 0px;
            &::-webkit-scrollbar,
            &::-webkit-scrollbar {
                height: 12px;
                width: 12px;
            }
            &::-webkit-scrollbar-track,
            &::-webkit-scrollbar-track {
                background: white;
            }
            &::-webkit-scrollbar-thumb,
            &::-webkit-scrollbar-thumb {
                background-color: #f5b047;
                border-radius: 5px;
                border: 3px solid white;
            }
        }
    }
}

.map {
    margin: 120px 0px;

    .container {
        flex-direction: column;
        align-items: start;
        gap: 36px;
    }

    &_content {
        position: relative;
        height: 500px;
        width: 100%;
        border-radius: 20px;
        overflow: hidden;
        &_map {
            width: 100%;
            height: 500px;
        }
    }
    .h2 {
        &::after {
            content: "Чтобы ознакомиться с местоположением объекта  на карте коснитесь его названия";
            font-size: clamp(16px, 2.5vw, 18px);
            max-width: 500px;
            line-height: 130%;
            display: block;
            padding-top: 14px;
            font-weight: 400;
        }
    }
    .ymaps-2-1-79-balloon__content {
        min-width: auto !important;
        min-width: 230px !important;
        min-height: 190px !important;
        width: max-content !important;
        & * {
            display: block;
        }
        & > ymaps {
            width: max-content !important;
            height: auto !important;
            img {
                width: auto !important;
                height: 100px !important;
            }
        }
    }
    .ymaps-2-1-79-balloon__tail {
        box-shadow: none !important;
    }
}
.otziv {
    padding: 120px 0px;
    background: url(/public/img/otziv/fon.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    &_menu {
        justify-content: space-between;
        padding-bottom: 60px;
    }
    &_slider {
        &_item {
            display: grid;
            grid-template-columns: 1fr 1.2fr;
            gap: 20px;
            min-height: 470px;
            width: 100%;
            padding: 0 1%;
            &_content {
                padding: 36px 46px;
                border: 1px solid var(--yellow);
                border-radius: 20px;
                height: min-content;
                margin: auto 0px;
                background: var(--white);
                &_author {
                    font-weight: 700;
                    color: var(--dark-blue);
                    padding-bottom: 30px;
                }

                &_text {
                    font-weight: 400;
                    line-height: 140%;
                    color: var(--dark-blue);
                }
            }

            &_img {
                min-height: 470px;
                width: 100%;
                border-radius: 20px;
                background-size: cover !important;
                background-repeat: no-repeat !important;
            }
        }
    }
}

@media screen and (max-width: 1650px) {
    .container {
        width: 1400px;
    }

    .test_content {
        width: 700px;
        left: 20%;
    }
}

@media screen and (max-width: 1450px) {
    .container {
        width: 1200px;
    }

    .test_content {
        width: 700px;
        left: 0%;
        height: 40vw;
    }

    .test_img {
        right: 18%;
    }

    .test_content .alice-carousel__stage-item {
        height: 32vw;
    }
}

@media screen and (max-width: 1250px) {
    .container {
        width: 900px;
    }

    .test_content {
        width: 500px;
        left: 0%;
        height: 45vw;
    }

    .test_img {
        right: 18%;
    }

    .test_content .alice-carousel__stage-item {
        height: 35vw;
    }

    .test_img_item_content {
        height: 88%;
    }

    .test_content_item_butt {
        padding: 10px 16px;
        height: 47px;
    }
}

@media screen and (max-width: 1400px) {
    .test_progressBar .progress {
        width: 780px;
    }
    .test_progressBar .steps {
        width: 780px;
    }
    .ekskurs_slider_item .t18 {
        padding: 10px 15px;
    }
}
@media screen and (max-width: 1300px) {
    .restor_menu:nth-child(1) {
        grid-template-columns: 1fr;
        gap: 30px;
    }
    .otziv_slider_item_content {
        padding: 25px;
    }
    .otziv_slider_item {
        grid-template-columns: repeat(1, 1fr);
        padding: 0 3%;
    }
}

@media screen and (max-width: 1200px) {
    .ekskurs_slider_item {
        width: 95%;
    }
    .header_logoBox img {
        height: 50px;
        width: auto;
    }
    .footer {
        padding: 19px 0px;
    }
    .info,
    .ekskurs {
        padding: 120px 0px;
    }
    .info_content {
        margin-top: 60px;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }
    .footer_nav {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 20px;
    }
    .footer .container {
        gap: 20px;
    }
    .test_progressBar .steps,
    .test_progressBar .progress {
        width: 410px;
    }
    .test_progressBar {
        width: 58%;
        bottom: 0%;
        z-index: 999;
        right: 0;
        justify-content: space-between;
    }
    .test_content_item {
        gap: 7px;
    }
    .test_content {
        padding: 20px 30px;
    }
    .test_content_item {
        gap: 15px;
    }
    .test_content_item_butt {
        padding: 12px 16px;
        height: auto;
    }
    .test .container {
        display: grid;
        grid-template-columns: 1fr;
        height: 100%;
        position: relative;
    }
    .test_content {
        background: #00314dab;
        border-radius: 0px;
        width: 100%;
        height: 100%;
        min-height: auto;
        padding: 30px 60px;
        position: relative;
        left: 0;
        bottom: 0;
        background-image: url(/public/img/test/fon2.png);
        background-position: bottom right;
        background-repeat: no-repeat;
        max-width: 100%;
        overflow: hidden;
        z-index: 5;
    }
    .test_img_item {
        // width: 100%;
        // height: 100%;
    }
    .test_img_item {
        position: relative;
        width: 102%;
        height: 45vw;
        background-size: cover !important;
        border-radius: 25px;
    }
    .test_img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: visible;
        .alice-carousel__wrapper {
            position: relative;
            overflow-x: hidden;
            overflow-y: hidden;
            box-sizing: border-box;
            width: 100%;
            height: auto;
        }
        &_item {
            position: relative;
            background-size: 120% auto !important;
            background-position: -80px center !important;
            background-repeat: no-repeat !important;
            border-radius: 0px;
        }
    }
    .test_img_item_content {
        padding-left: 25px;
        width: 76vw;
        height: 80%;
        overflow: scroll;
    }

    .test_img_item.otvets {
        padding: 0.5%;
    }
}

@media screen and (max-width: 1000px) {
    .footer .container {
        gap: 15px;
    }
    .footer_linkBox {
        gap: 30px;
    }
    .hello_content {
        gap: 20px;
    }
    .test {
        overflow: hidden;
    }
}

@media screen and (max-width: 970px) {
    .container {
        width: 700px;
    }

    .test_img_item_content {
        width: 690px;
    }

    li.test_img_item_content_item {
        padding: 10px 20px;
    }

    .test_img_item_content {
        padding-left: 40px;
    }

    h3.test_img_item_title {
        padding: 20px 20px 20px 20px;
    }

    li.alice-carousel__stage-item.__active.__target {
        width: 700px !important;
    }
    .test .container {
        border-radius: 20px;
        overflow: hidden;
        border: 3px solid #f5b047;
    }

    .header_navigate {
        gap: 20px;
    }
    .t16 {
        font-size: clamp(10px, 2vw, 12px);
    }
    ul.test_img_item_content {
        padding-bottom: 30px;
    }

    .test {
        background: url(http://localhost:3001/static/media/fon.01422f8….png);
        margin: 80px 0px 0px 0px;
        z-index: 1;
        height: 55vw;
        background-position: center bottom !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        position: relative;
    }

    .test_img_item {
        width: 100%;
        height: 56vw;
    }

    .test_content .alice-carousel__stage-item {
        height: 50vw;
    }
}

@media screen and (max-width: 750px) {
    .container {
        width: 90%;
    }
    .test ._butN {
        display: block;
        z-index: 9999;
    }
    li.alice-carousel__stage-item.__active.__target {
        width: 100% !important;
    }

    .test_img_item_content {
        width: 100%;
    }

    .test_img_item.otvets {
        width: 100%;
    }

    .oKrasnoyarsk {
        margin: 80px 0;
        & .container {
            gap: 20px;
        }
    }
    .header_navigate {
        // justify-content: space-between;
        flex-direction: column;
        gap: 25px;
        display: none;
    }
    .footer .container {
        flex-direction: column;
    }
    .info_content {
        grid-template-columns: repeat(1, 1fr);
    }
    .info_content_item_img {
        height: 53vw;
    }
    .ekskurs_hed,
    .footer_linkBox,
    .otel_filter,
    .oKrasnoyarsk_menu {
        flex-direction: column;
        align-items: start;
        gap: 20px;
    }
    .restor_menu {
        grid-template-columns: 1fr;
        gap: 20px;
    }
    .otel_filter_type {
        flex-wrap: wrap;
        justify-content: start;
    }
    .test {
        height: 120vw;
    }
    .otel_slide_item_img {
        height: 53vw;
    }
    .restor_slide .alice-carousel__stage-item {
        height: 536px;
    }
    .ekskurs_slider .alice-carousel__stage-item {
        // max-width: 345px;
        // min-width: 365px;
    }

    .test_content .alice-carousel__stage-item {
        width: 101% !important;
        min-width: 101% !important;
        max-width: 101% !important;
        height: 70vw;
    }

    .test_progressBar_step {
        display: none;
    }
    .test_progressBar {
        justify-content: end;
    }
    .test_img_item {
        height: 118vw;
    }
    .test_img_item {
        position: relative;
        background-size: 120% 83vw !important;
        background-position: -80px -21px !important;
        background-repeat: no-repeat !important;
        border-radius: 0px;
    }
    .header .wite {
        position: relative;
        bottom: 0px;
    }
    .hello_contact {
        gap: 10px;
        position: absolute;
        z-index: 5;
        bottom: 10px;
    }
    .hello_content_name {
        display: none;
    }
    .header_logoBox img {
        height: 30px;
    }
    .hello_content_but {
        padding: 10px 17px;
    }
    .oKrasnoyarsk_slider_item {
        padding: 23px;
        width: 99%;
        padding: 0 1%;
        max-width: none;
    }
    .oKrasnoyarsk_menu_switch {
        align-self: flex-end;
    }
    .oKrasnoyarsk_slider_item_name {
        padding-bottom: 25px;
    }
    .hello {
        display: none;
    }
    .hello.mob {
        height: 150vw;

        display: block;
        .bg_2 {
            height: 170vw;
        }
        .hello_fon.bg_4 {
            top: auto;
            bottom: -9vw;
            z-index: 5;
            overflow: hidden;
            width: 100%;
            animation: 5s strelAnim ease;
            height: 100vw;
        }
        .hello_fon.bg_4 img {
            width: 110vw;
            height: 180vw;
            min-width: 99vw;
            min-height: 49vw;
            position: absolute;
            bottom: -23vw;
            left: -7vw;
            pointer-events: none;
        }
    }
    .hello_fon.bg_1,
    .hello_fon.bg_3 {
        width: auto;
        left: -120%;
    }
    .menu {
        display: block;
    }
    .header_navigate.mob {
        display: flex;
        position: absolute;
        background: #fff;
        width: 100%;
        height: 100vh;
        top: 0vh;
        animation: 1s mob;
        left: 0;
        justify-content: center;
    }
    @keyframes mob {
        0% {
            top: -100vh;
        }
        100% {
            top: 0vh;
        }
    }
    .header_logoBox {
        height: 100%;
        z-index: 9;
    }
    .test_img_item {
        position: relative;
        background-size: cover !important;
        height: 157vw;
        width: 125%;
    }
    .test_img_item.otvets {
        background: #fff !important;
    }
}
@media screen and (max-width: 475px) {
    .test_content .alice-carousel__stage-item {
        width: 101% !important;
        min-width: 101% !important;
        max-width: 101% !important;
        height: 90vw;
    }
    .restor_menu_chek_list {
        flex-wrap: wrap;
        justify-content: start;
    }
    // .test_img_item{
    //     display: none;
    // }
    .restor_slide_item_img {
        height: 127px;
    }
    .restor {
        padding: 80px 0px;
    }
    .restor_slide .alice-carousel__stage-item {
        height: auto;
    }
}
@media screen and (max-width: 375px) {
    .test_content .alice-carousel__stage-item {
        height: 117vw;
    }

    // .test_img_item {
    //     position: relative;
    //     background-size: cover !important;
    //     height: 157vw;
    //     width: 90%;
    // }
    .test_content {
        padding: 19px 21px;
    }

    .test_img_item_content {
        height: 59%;
    }
    //    .alice-carousel__stage-item{
    //     width: 100% !important;
    //    }
    // .test_img_item.otvets{
    //     width: 260px;
    // }
}
